// Generated by Framer (6bae449)

import { addFonts, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/PKcEgKA8G";
const MotionDivWithFX = withFX(motion.div);

const enabledGestures = {TPXNRdFmo: {hover: true}};

const cycleOrder = ["TPXNRdFmo"];

const serializationHash = "framer-iP6zs"

const variantClassNames = {TPXNRdFmo: "framer-v-sm83pk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "TPXNRdFmo", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><MotionDivWithFX {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-sm83pk", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"TPXNRdFmo"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-c5030bf8-03e8-4ed5-8156-8ebb4b5627b7, rgb(20, 20, 31))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", boxShadow: "0px 0px 0px 0px var(--token-c5030bf8-03e8-4ed5-8156-8ebb4b5627b7, rgb(20, 20, 31))", ...style}} variants={{"TPXNRdFmo-hover": {boxShadow: "-10px 10px 0px 0px var(--token-c5030bf8-03e8-4ed5-8156-8ebb4b5627b7, rgb(20, 20, 31))"}}} {...addPropertyOverrides({"TPXNRdFmo-hover": {__framer__styleTransformEffectEnabled: true, __framer__transformTargets: [{target: {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 0, skewX: 0, skewY: 0, x: 0, y: 0}}, {target: {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 10, y: -10}}], __framer__transformTrigger: "onInView", __perspectiveFX: false, __smartComponentFX: true, __targetOpacity: 1, "data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-gtbfo7"} data-styles-preset={"PKcEgKA8G"}>Our work</motion.p></React.Fragment>} className={"framer-i7u4im"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"Qr0xlpaPS"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></MotionDivWithFX></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-iP6zs.framer-gzc4hm, .framer-iP6zs .framer-gzc4hm { display: block; }", ".framer-iP6zs.framer-sm83pk { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: 56px; justify-content: center; overflow: visible; padding: 8px 24px 8px 24px; position: relative; width: min-content; }", ".framer-iP6zs .framer-i7u4im { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-iP6zs.framer-sm83pk { gap: 0px; } .framer-iP6zs.framer-sm83pk > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-iP6zs.framer-sm83pk > :first-child { margin-left: 0px; } .framer-iP6zs.framer-sm83pk > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ".framer-iP6zs[data-border=\"true\"]::after, .framer-iP6zs [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 56
 * @framerIntrinsicWidth 156
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"bNsCsq_f9":{"layout":["auto","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerqAIh0i59J: React.ComponentType<Props> = withCSS(Component, css, "framer-iP6zs") as typeof Component;
export default FramerqAIh0i59J;

FramerqAIh0i59J.displayName = "button-md (v2)";

FramerqAIh0i59J.defaultProps = {height: 56, width: 156};

addFonts(FramerqAIh0i59J, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})